<template>
  <div class="acceleratorPackageRecord fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">加速包订购记录列表</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form"
        style="line-height: 40px; height: auto"
      >
        <el-form-item label="ICCID:">
          <el-input
            v-model="formInline.iccid"
            placeholder="请输入ICCID"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="短号:">
          <el-input
            v-model="formInline.shortIccid"
            placeholder="请输入短号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="标题:">
          <el-input
            v-model="formInline.title"
            placeholder="请输入标题"
            clearable
          ></el-input>
        </el-form-item>

        <!-- 状态 0 待支付 1 已支付 2 已退款 -->
        <el-form-item label="状态:">
          <el-select
            v-model="formInline.status"
            filterable
            placeholder="请选择订单状态"
            clearable
          >
            <el-option label="已支付" value="1"></el-option>
            <el-option label="待支付" value="0"></el-option>
            <el-option label="已退款" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单号:">
          <el-input
            v-model="formInline.orderNumber"
            placeholder="请输入订单号"
            clearable
          ></el-input>
        </el-form-item>

               <el-form-item label="开始时间:">
					<el-date-picker style="width: 100%;" placeholder="请选择开始时间" v-model="formInline.startTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
				</el-form-item>
				<el-form-item label="结束时间:">
					<el-date-picker style="width: 100%;" placeholder="请选择结束时间" v-model="formInline.endTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
				</el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="exportList"
            icon="el-icon-plus"
            class="themed-button"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        border
        ref="table"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="id" label="ID" min-width="180">
        </el-table-column>
        <el-table-column prop="iccid" label="卡号" min-width="130">
        </el-table-column>
        <el-table-column prop="shortIccid" label="短号" min-width="100">
        </el-table-column>
        <el-table-column prop="title" label="标题" min-width="130">
        </el-table-column>
        <el-table-column prop="price" label="价格" min-width="100">
        </el-table-column>
        <el-table-column prop="createTime" label="订购时间" min-width="100">
        </el-table-column>
        <el-table-column prop="remark" label="备注" min-width="100">
        </el-table-column>
        <el-table-column prop="statusStr" label="状态" min-width="100">
          <!-- <template slot-scope="scope">
            <span v-if="scope.row.status == '0'">下架</span>
            <span v-else-if="scope.row.status == '1'">上架</span>
          </template> -->
        </el-table-column>

        <el-table-column prop="orderNumber" label="订单号" min-width="130">
        </el-table-column>
        <el-table-column prop="orderCount" label="订购数量" min-width="130">
        </el-table-column>
        <el-table-column prop="refundMoney" label="退款金额" min-width="130">
        </el-table-column>
        <el-table-column prop="refundTime" label="退款时间" min-width="130">
        </el-table-column>

        <el-table-column label="操作" width="320px">
          <template v-slot:default="scope">
            <el-button
              type="info"
              class="themed-button"
              @click="refundMoney(scope.row.id)"
              v-if="scope.row.status == '1'"
              >退款</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="退款"
      :visible.sync="dialogRefundVisible"
      :destroy-on-close="true"
    >
      <el-form :model="refundForm">
        <el-form-item label="iccid:" label-width="100px">
          <span>{{ refundForm.iccid }}</span>
        </el-form-item>
        <el-form-item label="标题:" label-width="100px">
          <span>{{ refundForm.title }}</span>
        </el-form-item>
        <el-form-item label="金额:" label-width="100px">
          <span>{{ refundForm.price }}</span>
        </el-form-item>
        <el-form-item label="订购时间:" label-width="100px">
          <span>{{ refundForm.createTime }}</span>
        </el-form-item>
        <el-form-item label="订单号:" label-width="100px">
          <span>{{ refundForm.orderNumber }}</span>
        </el-form-item>
        <el-form-item label="退款金额:" label-width="100px">
          <el-input
            v-model="refundForm.refundMoney"
            autocomplete="off"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注:" label-width="100px">
          <el-input type="textarea" v-model="refundForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="themed-button"
          type="info"
          @click="dialogRefundVisible = false"
          >取 消</el-button
        >
        <el-button
          class="themed-button"
          type="primary"
          @click="sureRefund"
          v-preventReClick="1000"
          :disabled="isDisable"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import { mapGetters } from "vuex";
import {
  getAcceleratorPackageRecord,
  accPackRecordExport,
  basicInfoById,
  refundMoney
} from "@/api/acceleratorPackage.js";

export default {
  name: "acceleratorPackageRecord",
  mixins: [onresize],
  data() {
    // var validateFee = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请输入加速包费用"));
    //   } else {
    //     if (!/^\d+(\.\d{0,1})?$/.test(value)) {
    //       callback(new Error("加速包费用只能为数字最多保留1位小数"));
    //     }
    //      if (value>=1000) {
    //       callback(new Error("加速包费用不能大于等于1000"));
    //     }
    //     callback();
    //   }
    // };
    return {
      isDisable: false,
      dialogRefundVisible: false,
      refundForm: {
        refundMoney: "",
        remark: "",
        title: null,
        iccid: null,
        price: null,
        orderNumber: null,
        createTime: null,
        orderId:null,
      },
      page: 1,
      pageSize: 15,
      total: 0,
      formInline: {
        title: null,
        orderNumber: null,
        status: null,
        shortIccid: null,
        iccid: null,
         startTime:"",
       endTime:"",
      },
      tabledata: [],
    };
  },
  created() {
    this.getAcceleratorPackageRecordList();
  },
  components: {},
  computed: {
    ...mapGetters(["currentRole"]),
  },
  methods: {
    refundMoney(id) {
      //退款
      basicInfoById({ id }).then((res) => {
        this.dialogRefundVisible = true;
        this.refundForm.title = res.title;
        this.refundForm.iccid = res.iccid;
        this.refundForm.price = res.price;
        this.refundForm.orderNumber = res.orderNumber;
        this.refundForm.createTime = res.createTime;
        this.refundForm.orderId  = res.orderId
      });
    },
    sureRefund() {
        // !!this.refundForm.reMoney && parseFloat(this.refundForm.reMoney) > parseFloat(this.maxRemoney)
      if (!this.refundForm.refundMoney ) {
        this.$alert("请填写退款金额", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (
        parseFloat(this.refundForm.refundMoney) > parseFloat(this.refundForm.price)
      ) {
        this.$alert("退款金额不能大于订单金额", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      this.isDisable = true;
         let params = {
          refundMoney:this.refundForm.refundMoney,
          remark:this.refundForm.remark,
          orderId:this.refundForm.orderId,
         }
      refundMoney(params)//注意传参
        .then((res) => {
          this.isDisable = false;
          if (res.system_result_key == 0) {
            this.$message({
              showClose: true,
              message: "退款成功",
              type: "success",
            });
            this.dialogRefundVisible = false;
            this.getAcceleratorPackageRecordList();
          }
        })
        .catch(() => {
          this.isDisable = false;
        });
    },
    exportList() {
      //导出
      let params = {};
      if (!!this.formInline.title) {
        params.title = this.formInline.title;
      }
      if (!!this.formInline.orderNumber) {
        params.orderNumber = this.formInline.orderNumber;
      }
      if (!!this.formInline.status) {
        params.status = this.formInline.status;
      }
      if (!!this.formInline.shortIccid) {
        params.shortIccid = this.formInline.shortIccid;
      }
      if (!!this.formInline.iccid) {
        params.iccid = this.formInline.iccid;
      }
       if (!!this.formInline.startTime) {
        params.startTime = this.formInline.startTime;
      }
       if (!!this.formInline.endTime) {
        params.endTime = this.formInline.endTime;
      }
      accPackRecordExport(params).then((res) => {
        if (res.system_result_key == "0") {
          this.$alert("导出成功，请到导出记录中下载", "提示", {
            confirmButtonText: "确定",
          }).then(() => {
            this.$router.push("/tob/i_export_list");
          });
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
      });
    },

    getAcceleratorPackageRecordList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };

      if (!!this.formInline.title) {
        params.title = this.formInline.title;
      }
      if (!!this.formInline.orderNumber) {
        params.orderNumber = this.formInline.orderNumber;
      }
      if (!!this.formInline.status) {
        params.status = this.formInline.status;
      }
      if (!!this.formInline.shortIccid) {
        params.shortIccid = this.formInline.shortIccid;
      }
      if (!!this.formInline.iccid) {
        params.iccid = this.formInline.iccid;
      }
       if (!!this.formInline.startTime) {
        params.startTime = this.formInline.startTime;
      }
       if (!!this.formInline.endTime) {
        params.endTime = this.formInline.endTime;
      }
      console.log(312);
      getAcceleratorPackageRecord(params).then((res) => {
        /* 表格数据 */
        console.log(315);
        this.tabledata = res.rows;
        this.page = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getAcceleratorPackageRecordList();
    },
    onPageChange(page) {
      this.page = page;
      this.getAcceleratorPackageRecordList();
    },
    searchData() {
      this.page = 1;
      this.getAcceleratorPackageRecordList();
    },
  },
};
</script>

<style>
.right_agent {
  margin-right: 8px;
}
/* .getAcceleratorPackageRecordList .el-form-item__label {
  vertical-align: top;
} */
/* .acceleratorPackageList /deep/  .el-form--inline .el-form-item__content{vertical-align: middle;} */
.acceleratorPackageRecord .el-form--inline .el-form-item__content {
  vertical-align: middle;
}
</style>
