<template>
  <div class="rewardDetails fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">奖励明细</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
       	<el-form-item label="合伙人">
					<el-select v-model="formInline.partnerId" placeholder="合伙人" clearable >
						<el-option label="全部" value=""></el-option>
						<el-option v-for="(item) in groupList" :key="item.id" :label="item.nickname" :value="item.id"></el-option>
					</el-select>
		</el-form-item>
        	<el-form-item label="客户">
					<el-select v-model="formInline.agentId" placeholder="客户" clearable >
						<el-option label="全部" value=""></el-option>
						<el-option v-for="(item) in customerList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
		</el-form-item>
    <el-form-item label="订单审核状态">
            <!-- 状态 0 待审核 1 已审核 -->
					<el-select v-model="formInline.orderStatus"  clearable >
						<el-option label="全部" value=""></el-option>
						<el-option label="待审核" value="0"></el-option>
             <el-option label="已审核" value="1"></el-option>
					</el-select>
				</el-form-item>
        <el-form-item label="订单号：">
					<el-input v-model="formInline.orderNumber" placeholder="" clearable></el-input>
				</el-form-item>
				<el-form-item label="奖励ID：">
					<el-input v-model="formInline.id" placeholder="" clearable></el-input>
				</el-form-item>

				<el-form-item label="操作时间:">
					<el-date-picker v-model="value1"  type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
					  value-format="yyyy-MM-dd HH:mm:ss">
					</el-date-picker>
				</el-form-item>
         <el-form-item label="订单类型">
            <!--  //  1.充值，2.下单，3.收益转入, 4.公众号下单,5.接口下单,6.已退款,7.分配余额,8.分配余额 -->
					<el-select v-model="formInline.type"  clearable >
						<el-option label="全部" value=""></el-option>
						<el-option label="充值" value="1"></el-option>
            <el-option label="下单" value="2"></el-option>
            <el-option label="收益转入" value="3"></el-option>
            <el-option label="公众号下单" value="4"></el-option>
            <el-option label="接口下单" value="5"></el-option>
            <el-option label="已退款" value="6"></el-option>
					</el-select>
				</el-form-item>
       	<el-form-item >
					 <el-checkbox v-model="formInline.updateNull">已打款</el-checkbox>
				</el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item>
         <el-form-item>
           <el-button type="primary" class="themed-button" @click="rewardConfig"
            >奖励配置</el-button
          >
        </el-form-item>
           <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出</el-button>
				</el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        border
        ref="table"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="agentName" label="客户名称">
             </el-table-column>
        <el-table-column prop="orderNumber" label="订单号"> </el-table-column>
        <el-table-column prop="orderMoney" label="订单金额"> </el-table-column>
        <el-table-column prop="type" label="订单类型">
 <template slot-scope="scope">
  {{getOrderType(scope.row.type)}}
                   <!-- <span v-if="scope.row.type==1">购卡</span>
                    <span v-else-if="scope.row.type==2">续费</span> -->
                </template>
           </el-table-column>
            <el-table-column prop="orderStatus" label="订单审核状态"> 
          <template slot-scope="scope">
            <!-- <el-button  v-if="scope.row.orderStatus==0" size="mini" class="themed-button" type="primary" @click="handleCheck(scope.row)">去审核</el-button> -->
             <span  v-if="scope.row.orderStatus==0">待审核</span>  
              <span v-else-if="scope.row.orderStatus==1">已审核</span>        
          </template>
             <!--  0 待审核 1已审核 -->
                 
        </el-table-column>
        <el-table-column prop="rebateMoney" label="奖励金额"> </el-table-column>
        <el-table-column prop="rewardType" label="奖励类型">
            <template slot-scope="scope">
                   <span v-if="scope.row.rebateType==1">一级奖励</span>
                    <span v-else-if="scope.row.rebateType==2">二级奖励</span>
                </template>
           </el-table-column>
        <el-table-column prop="rebateRate" label="奖励比例"> </el-table-column>
         <el-table-column prop="partner" label="合伙人"> </el-table-column>
          <el-table-column prop="remark" label="备注">
              <template slot-scope="scope">
                    <el-input v-model="scope.row.remark" @change="((val)=>{updateSort(val,scope.row.id)})" ></el-input>
                </template>
             </el-table-column>
           <el-table-column prop="status" label="到账标记"> 
              <template slot-scope="scope">
                   <span v-if="scope.row.status==0">未到账</span>
                    <span v-else-if="scope.row.status==1">已到账</span>
                </template>

           </el-table-column>
            <el-table-column prop="createTime" label="创建时间"> </el-table-column>
            <el-table-column prop="updateTime" label="更新时间"> </el-table-column>
      </el-table>
     
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>
    <el-dialog title="奖励配置" :visible.sync="dialogFormVisible" v-if="dialogFormVisible">
  <el-form :model="form">
    <el-form-item label="一级奖励比例:" :label-width="formLabelWidth">
      <el-input-number v-model="form.TOB_FIRST_LEVEL_REBATE_RATE" :step="1" step-strictly :min="0" :max="100"></el-input-number> %  
       <el-button type="primary" @click="sureConfig(false)"  class="marginLeft">确 定</el-button>  
    </el-form-item>
     <div slot="footer" class="dialog-footer">
   
  </div>
    <el-form-item label="二级奖励比例:" :label-width="formLabelWidth">
     <el-input-number v-model="form.TOB_SECOND_LEVEL_REBATE_RATE" :step="1" step-strictly :min="0" :max="100"></el-input-number>%
       <el-button type="primary" @click="sureConfig(true)" class="marginLeft">确 定</el-button>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">关闭</el-button>
  
  </div>
</el-dialog>
  </div>
</template>

<script>
import {
  getConfigurationModify,
  setConfigurationModify,
} from "@/api/systemManagement.js";
import onresize from "@/mixins/resize.js";
import { mapGetters } from "vuex";
import {
  partnerList,editRemark,getRewardDetails,enterpriseList,exportExcelAsync
} from "@/api/partner.js";

export default {
  name: "rewardDetails",
  mixins: [onresize],
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      formInline: {
        agentId:"",
        orderNumber:"",
        partnerId:"",
        id:"",
        startTime:"",
        endTime:"",
        orderStatus:"",
        updateNull:false,
        type:"",
      },
      tabledata: [],
      formLabelWidth: "200px",
     groupList:[],
     customerList:[],
     value1:[],
     form:{
     TOB_FIRST_LEVEL_REBATE_RATE:1,
     TOB_SECOND_LEVEL_REBATE_RATE:1,
     },
     dialogFormVisible:false,
    };
  },
  watch:{
			value1(value){
				//console.log(value)
				this.formInline.startTime = value == null ? "" : value[0]
				this.formInline.endTime = value == null ? "" : value[1]
			}
		},
  created() {
    this.getEnterpriseList();//获取客户列表
    this.getPartnerList();//获取合伙人列表
    
    this.getRewardDetails();
  },
  methods: {  
    exportExcel(){
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
        updateNull: this.formInline.updateNull? 1 :""
      };
      params.agentId = this.formInline.agentId
      params.orderNumber = this.formInline.orderNumber
      params.partnerId = this.formInline.partnerId
      params.id = this.formInline.id
      params.startTime = this.formInline.startTime
      params.endTime = this.formInline.endTime
      params.orderStatus = this.formInline.orderStatus
      params.type = this.formInline.type
    
      // Object.assign(params, this.formInline);
      exportExcelAsync(params).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
    },
    //合伙人和客户 有关联关系；待调整
  getOrderType(type){
   let typeStr = ""
      //  1.充值，2.下单，3.收益转入, 4.公众号下单,5.接口下单,6.已退款,7.分配余额,8.分配余额
      if(type==1){
        typeStr="充值"
      }else if(type==2){
 typeStr="下单"
      }else if(type==3){
 typeStr="收益转入"
      }else if(type==4){
 typeStr="公众号下单"
      }else if(type==5){
 typeStr="接口下单"
      }else if(type==6){
 typeStr="已退款"
      }else if(type==7){
 typeStr="分配余额"
      }else if(type==8){
 typeStr="TOB二级支出扣减余额"
      }
      return typeStr
  },
  sureConfig(flag){
      let key 
      if(flag ){
        key = "TOB_SECOND_LEVEL_REBATE_RATE"
      }else{
        key = "TOB_FIRST_LEVEL_REBATE_RATE"
      }
      let params = {
        group:"TOB_REBATE_RATE",
      }
      if(flag){
      params[key] = this.form.TOB_SECOND_LEVEL_REBATE_RATE/100
      }else{
      params[key] = this.form.TOB_FIRST_LEVEL_REBATE_RATE/100
      }
      console.log(params)
      setConfigurationModify(params).then(res=>{
       console.log(res,465)
        this.$message({
              showClose: true,
              message: "奖励配置设置成功",
              type: "success",
              center: true,
            });
       this.dialogFormVisible= false
       
      })  
   
  },
  rewardConfig(){
    this.dialogFormVisible = true
    this.getRewardConfig();
  },
     getRewardConfig(){
          
getConfigurationModify({group:"TOB_REBATE_RATE"}).then(res=>{
   console.log(res,448) 
    for(let i  of res){
       console.log( i)
       if(i.dictKey =="TOB_FIRST_LEVEL_REBATE_RATE"){
          this.form.TOB_FIRST_LEVEL_REBATE_RATE = parseFloat(i.dictValue)*100
       }
       if(i.dictKey =="TOB_SECOND_LEVEL_REBATE_RATE"){
          this.form.TOB_SECOND_LEVEL_REBATE_RATE = parseFloat(i.dictValue)*100
       }
    }
  
   
})
      },
      
     updateSort(val,id){//编辑备注
            let parms = {id,}
             parms.remark = val
            editRemark(parms).then((res)=>{
               this.getRewardDetails()
            }).catch((res)=>{
                messageBox(this,'请求错误','操作繁忙请稍后重试或联系客服。')
            })
        },
         getEnterpriseList() {
      let params = {
        pageSize: 100000,
        pageNo: 1,
      };
     
      enterpriseList(params).then((res) => {
        this.customerList = res.rows;
      });
    },
    getPartnerList() {
      let params = {
        pageSize: 100000,
        pageNo: 1,
      };
      
      partnerList(params).then((res) => {
        this.groupList = res.rows
      
      });
    },
    getRewardDetails(){
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
        updateNull: this.formInline.updateNull? 1 :""
      };
      params.agentId = this.formInline.agentId
      params.orderNumber = this.formInline.orderNumber
      params.partnerId = this.formInline.partnerId
      params.id = this.formInline.id
      params.startTime = this.formInline.startTime
      params.endTime = this.formInline.endTime
      params.orderStatus = this.formInline.orderStatus
      params.type     =  this.formInline.type 
      // Object.assign(params, this.formInline);
      getRewardDetails(params).then((res) => {
        /* 表格数据 */
        this.tabledata = res.rows;
        this.page = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getRewardDetails();
    },
    onPageChange(page) {
      this.page = page;
      this.getRewardDetails();
    },
    searchData() {
      this.page = 1;
      this.getRewardDetails();
    },
  },
};
</script>

<style>
.right_agent {
  margin-right: 8px;
}
 .rewardDetails  .el-date-editor.el-input{width:100%!important;}
.rewardDetails .el-form-item__label {
  vertical-align: top;
}
.marginLeft{margin-left: 10px;}
</style>

