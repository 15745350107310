<template>
  <div class="batchCard table-hook fadeInRight">
    <div class="title-box">
      <span class="m-title">批量导入</span>
      <el-form
        inline
        class="hook right themed-form search-form"
        style="height: 40px; line-height: 40px"
      >
        <el-form-item label="导入类型:">
          <el-select v-model="type" placeholder="全部" filterable>
            <el-option label="全部" value=""></el-option>
            <el-option label="过期时间" value="1" v-if="dicOperte.one=='1'&&(currentRole==0 )"></el-option>
            <el-option label="窜卡复机" value="2"  v-if="dicOperte.two=='1'&&(currentRole==0 )"></el-option>
            <el-option label="设置阀值" value="4" v-if="dicOperte.four=='1'&&(currentRole==0 )"></el-option>
            <el-option label="删除卡" value="5" v-if="dicOperte.five=='1'&&(currentRole==0 )"></el-option>
            <el-option label="窜卡" value="6" v-if="dicOperte.six=='1'&&(currentRole==0)"></el-option>
            <el-option label="变更套餐" value="7" v-if="dicOperte.seven=='1'&&(currentRole==0 ||(currentRole==4&&agentTree==1))"></el-option>
            <el-option label="机卡重绑" value="9" v-if="dicOperte.nine=='1'&&(currentRole==0)"></el-option>
            <el-option label="网卡回收" value="10" v-if="dicOperte.ten=='1'&&(currentRole==0 ||(currentRole==4&&agentTree==1))"></el-option>
            <el-option label="网卡注销" value="11" v-if="dicOperte.eleven=='1'&&(currentRole==0)"></el-option>
            <el-option label="不续费充值" value="12" v-if="dicOperte.twelve=='1'&&(currentRole==0)"></el-option>
            <el-option label="增减流量" value="13" v-if="dicOperte.thirteen=='1'&&(currentRole==0)"></el-option>
            <el-option label="导入IMEI库" value="16" v-if="dicOperte.sixteen=='1'&&(currentRole==0)"></el-option>
            <el-option label="修改类型" value="17" v-if="dicOperte.seventeen=='1'&&(currentRole==0)"></el-option>
            <el-option label="设置过期时间" value="18" v-if="dicOperte.eighteen=='1'&&(currentRole==0)"></el-option>
            <el-option label="计费组达量闸值" value="19" v-if="dicOperte.nineteen=='1'&&(currentRole==0)"></el-option>
            <el-option label="取消单独断网" value="20" v-if="dicOperte.twenty=='1'&&(currentRole==0)"></el-option>
            <el-option label="网卡备注" value="21" v-if="dicOperte.twentyOne=='1'&&(currentRole==0)"></el-option>
            <el-option label="虚拟卡数据清零" value="22" v-if="dicOperte.twentytwo=='1'&&(currentRole==0 ||(currentRole==4&&agentTree==1))"></el-option>
            <el-option label="停复机" value="23" v-if="dicOperte.twentythree=='1'&&(currentRole==0 ||(currentRole==4&&agentTree==1))"></el-option>
            <el-option label="停用虚拟卡绑定" value="24" v-if="dicOperte.twentyfour=='1'&&(currentRole==0)"></el-option>
            <el-option label="运营商" value="25" v-if="dicOperte.twentyfive=='1'&&(currentRole==0)"></el-option>
            <el-option label="短号" value="26" v-if="dicOperte.twentysix=='1'&&(currentRole==0)"></el-option>
            <!-- <el-option label="接入号" value="27"></el-option>
                     <el-option label="iccid修改" value="28"></el-option> -->
            <el-option label="沉默期激活" value="30" v-if="dicOperte.thirty=='1'&&(currentRole==0)"></el-option>
            <el-option label="沉默期修改" value="31" v-if="dicOperte.thirtyone=='1'&&(currentRole==0)"></el-option>
            <el-option label="变更充值模式" value="32" v-if="dicOperte.thirtytwo=='1'&&(currentRole==0)"></el-option>
            <el-option label="提前生效套餐" value="33" v-if="dicOperte.thirtythree=='1'&&(currentRole==0)"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="themed-button"
            @click="searchTable"
            >搜索查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-plus"
            class="themed-button"
            @click="importRecord"
            >批量导入</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        :data="tableData"
        stripe
        border
        :max-height="maxTableHeight"
      >
        <el-table-column label="导入总数" prop="importTotal"></el-table-column>
        <el-table-column label="成功数" prop="importSuccess"></el-table-column>
        <el-table-column label="失败数" prop="importFail"></el-table-column>
        <el-table-column label="导入类型" prop="type">
          <template slot-scope="scope">
            <span v-if="scope.row.type === 1">过期时间</span>
            <span v-if="scope.row.type === 2">窜卡复机</span>
            <span v-if="scope.row.type === 4">设置阀值</span>
            <span v-if="scope.row.type === 5">删除卡</span>
            <span v-if="scope.row.type === 6">窜卡</span>
            <span v-if="scope.row.type === 7">变更套餐</span>
            <span v-if="scope.row.type === 9">机卡重绑</span>
            <span v-if="scope.row.type === 10">网卡回收</span>
            <span v-if="scope.row.type === 11">网卡注销</span>
            <span v-if="scope.row.type === 12">不续费充值</span>
            <span v-if="scope.row.type === 13">增减流量</span>
            <span v-if="scope.row.type === 16">导入IMEI库</span>
            <span v-if="scope.row.type === 17">修改类型</span>
            <span v-if="scope.row.type === 18">设置过期时间</span>
            <span v-if="scope.row.type === 19">计费组达量闸值</span>
            <span v-if="scope.row.type === 20">取消单独断网</span>
            <span v-if="scope.row.type === 21">网卡备注</span>
            <span v-if="scope.row.type === 22">虚拟卡数据清零</span>
            <span v-if="scope.row.type === 23">停复机</span>
            <span v-if="scope.row.type === 24">停用虚拟卡绑定</span>
            <span v-if="scope.row.type === 25">运营商</span>
            <span v-if="scope.row.type === 26">短号</span>
            <!-- <span v-if="scope.row.type===27">接入号</span>
                    <span v-if="scope.row.type===28">iccid修改</span> -->
            <span v-if="scope.row.type === 30">沉默期激活</span>
            <span v-if="scope.row.type === 31">沉默期修改</span>
            <span v-if="scope.row.type === 32">变更充值模式</span>
            <span v-if="scope.row.type === 33">提前生效套餐</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作人"
          prop="operatorName"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="操作时间"
          prop="createDatetime"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="themed-button"
              @click="loadFail(scope.row.id)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog title="批量导入" :visible.sync="dialogBox">
      <el-form
        label-position="right"
        label-width="120px"
        :model="form"
        ref="form"
        v-loading="dialogLoading"
        :rules="rules"
      >
        <el-form-item label="导入类型:" prop="type" required>
          <el-radio-group v-model="form.type" @change="typeChange">
            <el-radio :label="1" v-if="dicOperte.one=='1'&&(currentRole==0)"  >过期时间</el-radio>
            <el-radio :label="2" v-if="dicOperte.two=='1'&&(currentRole==0)">窜卡复机</el-radio>
            <el-radio :label="4" v-if="dicOperte.four=='1'&&(currentRole==0)" >达量阀值</el-radio>
            <el-radio :label="5" v-if="dicOperte.five=='1'&&(currentRole==0)">删除卡</el-radio>
            <el-radio :label="6" v-if="dicOperte.six=='1'&&(currentRole==0)">窜卡</el-radio>
            <el-radio :label="7" @change="_getSetMealByCategory" v-if="dicOperte.seven=='1'&&(currentRole==0 ||(currentRole==4&&agentTree==1))"
              >变更套餐</el-radio
            >
            <el-radio :label="9" v-if="dicOperte.nine=='1'&&(currentRole==0)">机卡重绑</el-radio>
            <el-radio :label="10" v-if="dicOperte.ten=='1'&&(currentRole==0 ||(currentRole==4&&agentTree==1))">网卡回收</el-radio>
            <el-radio :label="11" v-if="dicOperte.eleven=='1'&&(currentRole==0)">网卡注销</el-radio>
            <el-radio :label="12" v-if="dicOperte.twelve=='1'&&(currentRole==0)">不续费充值</el-radio>
            <el-radio :label="13" v-if="dicOperte.thirteen=='1'&&(currentRole==0)">增减流量</el-radio>
            <el-radio :label="16" @change="_accountList" v-if="dicOperte.sixteen=='1'&&(currentRole==0)">导入IMEI库</el-radio>
            <el-radio :label="17" v-if="dicOperte.seventeen=='1'&&(currentRole==0)">修改类型</el-radio>
            <el-radio :label="18" v-if="dicOperte.eighteen=='1'&&(currentRole==0)">设置过期时间</el-radio>
            <el-radio :label="19" v-if="dicOperte.nineteen=='1'&&(currentRole==0)">计费组达量闸值</el-radio>
            <el-radio :label="20" v-if="dicOperte.twenty=='1'&&(currentRole==0)">取消单独断网</el-radio>
            <el-radio :label="21" v-if="dicOperte.twentyOne=='1'&&(currentRole==0)">网卡备注</el-radio>
            <el-radio :label="22" v-if="dicOperte.twentytwo=='1'&&(currentRole==0 ||(currentRole==4&&agentTree==1))">虚拟卡数据清零</el-radio>
            <el-radio :label="23" v-if="dicOperte.twentythree=='1'&&(currentRole==0 ||(currentRole==4&&agentTree==1))">停复机</el-radio>
            <el-radio :label="24" v-if="dicOperte.twentyfour=='1'&&(currentRole==0)">停用虚拟卡绑定</el-radio>
            <el-radio :label="25" @change="_accountList" v-if="dicOperte.twentyfive=='1'&&(currentRole==0)">运营商</el-radio>
            <el-radio :label="26" v-if="dicOperte.twentysix=='1'&&(currentRole==0)">短号</el-radio>
            <!-- <el-radio :label="27">接入号</el-radio>
                    <el-radio :label="28">iccid修改</el-radio> -->
            <el-radio :label="30" v-if="dicOperte.thirty=='1'&&(currentRole==0)">沉默期激活</el-radio>
            <el-radio :label="31" v-if="dicOperte.thirtyone=='1'&&(currentRole==0)">沉默期修改</el-radio>
            <el-radio :label="32" v-if="dicOperte.thirtytwo=='1'&&(currentRole==0)">变更充值模式</el-radio>
            <el-radio :label="33" v-if="dicOperte.thirtythree=='1'&&(currentRole==0)">提前生效套餐</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择excel文件:" :required="isRequired">
          <input class="file-input" type="file" ref="file" />
          <div class="mt5">
            <span class="text-color" style="font-size: 12px"
              >仅支持.xls/.xlsx格式</span
            >
          </div>
        </el-form-item>
        <el-form-item
          label="ICCID/短号:"
          v-if="
            form.type === 10 ||
            form.type === 25 ||
            form.type === 26 ||
            form.type === 32 || form.type === 33 || form.type === 7
          "
        >
          <el-input
            v-model="form.iccids"
            type="textarea"
            :placeholder="placeholderText"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="运营商账号:"
          required
          prop="account"
          v-if="form.type === 16 || form.type === 25"
        >
          <el-select
            v-model="form.account"
            filterable
            clearable
            placeholder="请选择运营商"
            @change="changeGetMeal"
          >
            <el-option
              v-for="(item, index) in imeiAccountList"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <!-- <search-select :optionsMetaAll="imeiAccountList" v-model="form.account" @change="changeGetMeal"></search-select> -->
        </el-form-item>
        <el-form-item
          label="套餐:"
          prop="setMealId"
          v-if="form.type === 25"
        >
          <el-select
            v-model="form.setMealId"
            :filterable="packageLists.length > 0"
            placeholder="请选择套餐"
            clearable
            @visible-change="secondClick"
          >
            <el-option
              v-for="(item, index) in packageLists"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="导入方式:"
          required
          prop="imeiIsDelete"
          v-if="form.type === 16"
        >
          <el-select v-model="form.imeiIsDelete" placeholder="请选择">
            <el-option label="导入" value="false"></el-option>
            <el-option label="删除" value="true"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="新套餐:"
          required
          prop="newMeals"
          v-if="form.type === 7"
        >
          <el-select v-model="form.newMeals" placeholder="请选择" filterable>
            <el-option
              v-for="item in newMealsList"
              :key="item.index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="操作类型:"
          required
          prop="action"
          v-if="form.type === 4"
        >
          <el-select v-model="form.action" placeholder="请选择">
            <el-option label="新增达量断网" value="1"></el-option>
            <el-option label="修改达量断网" value="2"></el-option>
            <el-option label="取消达量断网" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="断网阈值:"
          required
          prop="quota"
          v-if="form.type === 4"
        >
          <el-input v-model="form.quota"></el-input>
          <span>（只能输入数字，单位：M，-1表示无限制）</span>
        </el-form-item>
        <el-form-item
          label="单卡闸值达量断网:"
          required
          prop="flowStop"
          v-if="form.type === 19"
        >
          <el-input v-model="form.flowStop"></el-input>
          <span>（只能输入数字，单位：M）</span>
        </el-form-item>
        <el-form-item
          label="断网类型:"
          required
          prop="daliangType"
          v-if="form.type === 4"
        >
          <el-select v-model="form.daliangType" placeholder="请选择">
            <el-option label="用户总使用量" value="1"></el-option>
            <el-option label="超出套餐外使用量" value="2"></el-option>
          </el-select>
          <p>（用户级，阈值次月恢复；套餐级，阈值次周期恢复）</p>
        </el-form-item>
        <el-form-item
          label="更改方式:"
          required
          prop="expiredType"
          v-if="form.type === 1"
        >
          <el-radio-group v-model="form.expiredType">
            <el-radio :label="1"
              >单独变更（单卡多套餐分别增减过期时间）</el-radio
            >
            <el-radio :label="2">统一变更（单卡所有套餐统一变更）</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="业务类型:"
          required
          prop="toType"
          v-if="form.type === 17"
        >
          <el-select v-model="form.toType" placeholder="请选择">
            <el-option label="请选择" value=""></el-option>
            <el-option
              v-for="(item, index) in w_TOTYPE_"
              :key="index"
              :label="item.groupName"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="销售类型:"
          required
          prop="saleType"
          v-if="form.type === 17"
        >
          <el-select v-model="form.saleType" placeholder="请选择" >
            <el-option label="请选择" value=""></el-option>
            <el-option label="线上" value="1"></el-option>
            <el-option label="线下" value="2" v-if="isCaptal!='1'" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="合作类型:"
          required
          prop="coType"
          v-if="form.type === 17"
        >
          <el-select v-model="form.coType" placeholder="请选择">
            <el-option label="请选择" value=""></el-option>
            <el-option
              v-for="(item, index) in w_COTYPE_"
              :key="index"
              :label="item.groupName"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="产品类型:"
          required
          prop="productType"
          v-if="form.type === 17"
        >
          <el-select v-model="form.productType" placeholder="请选择">
            <el-option label="请选择" value=""></el-option>
            <el-option
              v-for="(item, index) in w_PRODUCT_"
              :key="index"
              :label="item.groupName"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="有效期:"
          required
          prop="expireTime"
          v-if="form.type === 18"
        >
          <el-date-picker
            v-model="form.expireTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="更改方式:"
          required
          prop="status"
          v-if="form.type === 23"
        >
          <el-radio-group v-model="form.status">
            <el-radio :label="0">停机</el-radio>
            <el-radio :label="1">复机</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="沉默期时间:"
          required
          prop="silentExpireTime"
          v-if="form.type === 31"
        >
          <el-date-picker
            v-model="form.silentExpireTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="充值类型:"
          required
          prop="rechargeType"
          v-if="form.type === 32"
        >
          <el-select v-model="form.rechargeType" placeholder="请选择">
            <el-option label="请选择" value=""></el-option>
            <el-option label="普通类型" value="1"></el-option>
            <el-option label="预存话费类型" value="2" v-if="parseInt(isGuangyao)"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" class="themed-button" @click="uploadExcel"
          >确认</el-button
        >
        <el-button
          type="primary"
          class="themed-button"
          @click="exportTemplateExcel"
          >导出excel模板</el-button
        >
        <el-button
          type="primary"
          class="themed-button"
          @click="dialogBox = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import { gridSetMealForImportCard } from "@/api/cardManagement/cardImport.js";
import {
  importList,
  getSetMealByCategory,
  accountList,
  importExcel,
} from "@/api/cardManagement/batchCard.js";
import { messageBox, confirmBox } from "@/utils/common.js";
import SearchSelect from "@/components/SearchSelect";
import Utils from "@/utils/utils.js";
import { exportTemplate } from "@/api/statistics.js";
import resize from "@/mixins/resize.js";
import dictGroup from "@/mixins/dictGroup.js";
import { mapGetters } from "vuex";
import { getOperateList } from "@/api/cardManagement/cardBillDetail.js";
export default {
  name: "batchCard",
  mixins: [resize, dictGroup],
  data() {
    return {
      isCustomer:this.isGuangyao,
      dialogLoading: false,
      type: "",
      tableData: [],
      pageSize: 15,
      currentPage: 1,
      total: 0,
      dialogBox: false,
      agentLists: [],
      imeiAccountList: [],
      newMealsList: [],
      isGetMeal: true,
      form: {
        type: 1,
        account: "",
        imeiIsDelete: "false",
        newMeals: "",
        action: "1",
        quota: "",
        flowStop: "",
        daliangType: "1",
        expiredType: 1,
        toType: "",
        saleType: "",
        coType: "",
        productType: "",
        status: 0,
        iccids: "",
        setMealId: "",
        rechargeType: "",
      },
      isRequired: true,
      placeholderText: "",
      packageLists: [], //套餐
      dicOperte: {
        one: "1",
        two: "1",
        four: "1",
        five: "1",
        six: "1",
        seven: "1",
        nine: "1",
        ten: "1",
        eleven: "1",
        twelve: "1",
        thirteen: "1",
        sixteen: "1",
        seventeen: "1",
        eighteen: "1",
        nineteen: "1",
        twenty: "1",
        twentyOne: "1",
        twentytwo: "1",
        twentythree: "1",
        twentyfour: "1",
        twentyfive: "1",
        twentysix: "1",
        thirty: "1",
        thirtyone: "1",
        thirtytwo: "1",
        thirtythree: "1",
      },
       rules: {
                account: [
                    { required: true, message: '请选择运营商', trigger: 'change' }
                ]}
    };
  },
  mounted() {
    this._importList();
    if (this.isCaptal == "1") {
      this.getOperateList();
    }
    // dicOperte.seven=='1'&&(currentRole==0 ||(currentRole==4&&agentTree==1))
    if(this.dicOperte.seven=='1' && this.currentRole==0  ){
      this.form.type =1
    }else if(this.dicOperte.seven=='1' &&(this.currentRole==4&&this.agentTree==1)){
     this.form.type =7
     this._getSetMealByCategory()
    }
  },
  methods: {
    getOperateList() {
      getOperateList({ group: "batch_import_menu" }).then((res) => {
        console.log(res);
        try {
          res.forEach((item) => {
            console.log(item.dictKey);
            console.log(item.dictValue);
            if (item.dictKey == "1") {
              this.dicOperte.one = item.dictValue;
            } else if (item.dictKey == "2") {
              this.dicOperte.two = item.dictValue;
            } else if (item.dictKey == "4") {
              this.dicOperte.four = item.dictValue;
            } else if (item.dictKey == "5") {
              this.dicOperte.five = item.dictValue;
            } else if (item.dictKey == "6") {
              this.dicOperte.six = item.dictValue;
            } else if (item.dictKey == "7") {
              this.dicOperte.seven = item.dictValue;
            } else if (item.dictKey == "9") {
              this.dicOperte.nine = item.dictValue;
            } else if (item.dictKey == "10") {
              this.dicOperte.ten = item.dictValue;
            } else if (item.dictKey == "11") {
              this.dicOperte.eleven = item.dictValue;
            } else if (item.dictKey == "12") {
              this.dicOperte.twelve = item.dictValue;
            } else if (item.dictKey == "13") {
              this.dicOperte.thirteen = item.dictValue;
            } else if (item.dictKey == "16") {
              this.dicOperte.sixteen = item.dictValue;
            } else if (item.dictKey == "17") {
              this.dicOperte.seventeen = item.dictValue;
            } else if (item.dictKey == "18") {
              this.dicOperte.eighteen = item.dictValue;
            } else if (item.dictKey == "19") {
              this.dicOperte.nineteen = item.dictValue;
            } else if (item.dictKey == "20") {
              this.dicOperte.twenty = item.dictValue;
            } else if (item.dictKey == "21") {
              this.dicOperte.twentyOne = item.dictValue;
            } else if (item.dictKey == "22") {
              this.dicOperte.twentytwo = item.dictValue;
            } else if (item.dictKey == "23") {
              this.dicOperte.twentythree = item.dictValue;
            } else if (item.dictKey == "24") {
              this.dicOperte.twentyfour = item.dictValue;
            } else if (item.dictKey == "25") {
              this.dicOperte.twentyfive = item.dictValue;
            } else if (item.dictKey == "26") {
              this.dicOperte.twentysix = item.dictValue;
            } else if (item.dictKey == "30") {
              this.dicOperte.thirty = item.dictValue;
            } else if (item.dictKey == "31") {
              this.dicOperte.thirtyone = item.dictValue;
            } else if (item.dictKey == "32") {
              this.dicOperte.thirtytwo = item.dictValue;
            } 
          });
        } catch (error) {}

        // let arr =[]
        // arr=  res.filter(itemone=>{
        // 	return  itemone.dictValue=="1"
        // })
        // try {
        //  this.tabledata  =   this.tabledata.filter(item=>{
        // return 	arr.some(ele=>
        // {     console.log(ele.dictKey,item.switchType)
        // 	return  ele.dictKey===item.switchType.toString()})
        //    })
        //    console.log( this.tabledata)
        // } catch (error) {}
      });
    },
    secondClick(val) {
      setTimeout(() => {
        if (this.packageLists.length == 0 && val) {
          this.$alert("请先选择运营商", "提示", {
            confirmButtonText: "确定",
          });
          return;
        }
      }, 500);
    },
    changeGetMeal(val) {
      if (this.form.type != 25) {
        return;
      }
      this.form.setMealId = "";
      console.log(val)
      if(val){
       gridSetMealForImportCard({ accountId: val }).then((res) => {
        console.log(res);
        this.packageLists = [];
        if (res.data.length != 0) {
          res.data.forEach((item) => {
            if (item.type != 11) {
              this.packageLists.push({ label: item.name, value: item.id });
            }
          })
        }
      })
      }
      
    },
    typeChange(val) {
      if (val === 10) {
        this.isRequired = false;
        this.placeholderText = "请输入ICCID/短号";
      } else {
        this.isRequired = true;
        if (val === 25) {
          this.placeholderText = "请输入ICCID/短号";
        } else if (val === 26) {
          this.placeholderText = "请输入ICCID和短号,每行一组，用英文逗号隔开";
        }
        // else if(val===27){
        //     this.placeholderText = "请输入接入号和ICCID,每行一组，用英文逗号隔开"
        // }else if(val===28){
        //     this.placeholderText = "请输入原ICCID、接入号和新ICCID,每行一组，用英文逗号隔开"
        // }
      }
    },
    exportTemplateExcel() {
      let name;
      if (this.form.type == 1) {
        if (this.form.expiredType == 1) {
          name = "expiredTimeD.xlsx";
        } else {
          name = "expiredTimeT.xlsx";
        }
      } else if (this.form.type == 2) {
        // name ="recovery.xlsx"
        name = "importIccidOrShort.xls";
      } else if (this.form.type == 30) {
        name = "importIccid.xls";
      } else if (this.form.type == 3) {
        // name ="stop.xlsx"
        name = "importIccidOrShort.xls";
      } else if (this.form.type == 4) {
        // name ="threshold.xlsx"
        name = "importIccidOrShort.xls";
      } else if (this.form.type == 5) {
        // name ="stop.xlsx"
        name = "importIccidOrShort.xls";
      } else if (this.form.type == 6) {
        // name ="usurp.xlsx"
        name = "importIccidOrShort.xls";
      } else if (this.form.type == 7) {
        // name ="usurp.xlsx"
        name = "importIccidOrShort.xls";
      } else if (this.form.type == 9) {
        // name ="deviceBindCard.xlsx"
        name = "importIccid.xls";
      } else if (this.form.type == 10) {
        // name ="sim_recycle.xls"
        name = "importIccidOrShort.xls";
      } else if (this.form.type == 11) {
        // name ="cancel.xlsx"
        name = "importIccidOrShort.xls";
      } else if (this.form.type == 12) {
        // name ="noRecharge.xlsx"
        name = "importIccidOrShort.xls";
      } else if (this.form.type == 13) {
        name ="modify_flow.xls"
        // name = "importIccidOrShort.xls";
      } else if (this.form.type == 16) {
        name = "imei-base.xls";
      } else if (this.form.type == 17) {
        // name ="threshold.xlsx"
        name = "importIccidOrShort.xls";
      } else if (this.form.type == 18) {
        name = "importIccidOrShort.xls";
      } else if (this.form.type == 19) {
        // name = "threshold.xlsx";
        name = "importIccid.xls";
      } else if (this.form.type == 20) {
        // name = "threshold.xlsx";
        name = "importIccidOrShort.xls";
      } else if (this.form.type == 21) {
        name = "remark_iccid.xls";
      } else if (this.form.type == 22) {
        name = "importIccidOrShort.xls";
      } else if (this.form.type == 23) {
        if (this.form.status == 0) {
          // name ="coverOrstop.xlsx"  //导出停机模板
          name = "importIccidOrShort.xls"; //统一模板名称
        } else {
          // name = "coverOrstop.xlsx";//导出复机模板
          name = "importIccidOrShort.xls";
        }
      } else if (this.form.type == 24) {
        name = "importIccidOrShort.xls";
      } else if (this.form.type == 25) {
        name = "importIccidOrShort.xls";
      } else if (this.form.type == 26) {
        name = "importShortIccid.xls";
      } else if (this.form.type == 31) {
        name = "importIccid.xls";
      } else if (this.form.type == 32) {
        name = "importIccidOrShort.xls";
      }else if (this.form.type == 33) {//需要确认
        name = "importIccidOrShort.xls";
      }
      exportTemplate({ name }).then((res) => {
        const filename = name;
        Utils.createDownload(res, filename);
      });
    },
    uploadExcel() {
      confirmBox(this, "确定导入吗？", "操作提示", () => {
        this.comfirmImport();
      });
    },
    comfirmImport() {
      let file = this.$refs["file"].files[0];
      if (file) {
        let fileName = file.name;
        let index = fileName.lastIndexOf(".");
        if (
          index < 0 ||
          !(
            fileName.substring(index + 1, fileName.length) == "xls" ||
            fileName.substring(index + 1, fileName.length) == "xlsx"
          )
        ) {
          messageBox(this, "文件格式错误");
          return;
        } else if (!!Utils.checkFile(file)) {
          if (Utils.checkFile(file) == 1) {
            messageBox(this, "上传文件不能超过20M");
            return;
          } else if (Utils.checkFile(file) == 2) {
            messageBox(this, "上传文件名称长度不能超过36个字符");
            return;
          }
        } else {
          let params = new FormData();
          params.append("fileurl", file);
          params.append("type", this.form.type);
          params.append("fromRange", false);
          let url;
          if (this.form.type == 1) {
            params.append("expiredType", this.form.expiredType);
            if (this.form.expiredType == 1) {
              url = "card/import/expiredTimeSync.do?";
            } else {
              url = "card/import/expiredTimeSync.do?";
              params.append("type", 2);
            }
          } else if (this.form.type == 2) {
            url = "card/import/recoveryCardSync.do?";
          } else if (this.form.type == 3) {
            url = "card/import/stopCardSync.do?";
          } else if (this.form.type == 4) {
            params.append("action", this.form.action);
            params.append("quota", this.form.quota);
            params.append("daliangType", this.form.daliangType);
            url = "card/import/thresholdSync.do?";
          } else if (this.form.type == 5) {
            url = "card/import/deleteCardSync.do?";
          } else if (this.form.type == 6) {
            url = "card/import/usurpCardSync.do?";
          } else if (this.form.type == 7) {
            params.append("newMealId", this.form.newMeals);
            url = "card/import/changeMealSync.do?";
          } else if (this.form.type == 9) {
            url = "card/import/deviceBindCardSync.do?";
          } else if (this.form.type == 10) {
            url = "record/cardRecycleRecord/cardRecycleByExcelSync.do?";
          } else if (this.form.type == 11) {
            url = "card/import/cancelSync.do?";
          } else if (this.form.type == 12) {
            url = "card/import/noRechargeSync.do?";
          } else if (this.form.type == 13) {
            url = "record/updateFlowRecord/updateFlowByExcelSync.do?";
          } else if (this.form.type == 16) {
            params.append("accountId", this.form.account);
            params.append("isDelete", this.form.imeiIsDelete);
            url = "card/imeibase/import4imeiSync.do?";
          } else if (this.form.type == 17) {
            params.append("toType", this.form.toType);
            params.append("saleType", this.form.saleType);
            params.append("coType", this.form.coType);
            params.append("productType", this.form.productType);
            url = "card/import/changeTypeSync.do?";
          } else if (this.form.type == 18) {
            params.append("expireTime", this.form.expireTime);
            url = "card/import/setExpireSync.do?";
          } else if (this.form.type == 19) {
            params.append("flowStop", this.form.flowStop);
            url = "chargeGroup/importCardStopSync.do?";
          } else if (this.form.type == 20) {
            url = "card/import/cancelDxDisconnectedAsync.do?";
          } else if (this.form.type == 21) {
            url = "cardRemark/updateB.do?";
          } else if (this.form.type == 22) {
            url = "/card/batchRejectClear.do?";
          } else if (this.form.type == 23) {
            params.append("status", this.form.status);
            url = "card/coverOrstop.do?";
          } else if (this.form.type == 24) {
            url = "card/batchStopBind.do?";
          } else if (this.form.type == 25) {
            if (this.form.account == "") {
              this.$alert("请选择运营商", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
            // console.log(this.form.setMealId == "",this.form.setMealId)
            // if (this.form.setMealId == "") {
            //   this.$alert("请选择套餐", "提示", {
            //     confirmButtonText: "确定",
            //   });
            //   return;
            // }
            params.append("accountId", this.form.account);
            params.append("setMealId", this.form.setMealId);
            url = "/card/accountEditByExcelSync.do?";
          } else if (this.form.type == 26) {
            url = "/card/shortIccidEditByExcelSync.do?";
          } else if (this.form.type == 30) {
            url = "/card/batchForceActive.do?";
          } else if (this.form.type == 31) {
            console.log(this.form.silentExpireTime);
            if (this.form.silentExpireTime == undefined) {
              this.$alert("请选择沉默期时间", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
            params.append("silentExpireTime", this.form.silentExpireTime);
            url = "/card/batchSetSilentTime.do?";
          } else if (this.form.type == 32) {
            if (this.form.rechargeType == "") {
              this.$alert("请选择充值类型", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
            params.append("rechargeType", this.form.rechargeType);
            url = "/card/reChargeTypeEditByExcelSync.do?";
          }else if (this.form.type == 33) {
            url = "/card/advanceLifeByExcelSync.do?";
          }
          importExcel(url, params).then((res) => {
            if (res.system_result_key == "0") {
              this.$alert("导入成功，请到导入记录中下载", "提示", {
                confirmButtonText: "确定",
              }).then(() => {
                this.dialogBox = false;
                this.$router.push("/tob/i_import_list");
              });
            } else {
              this.$message({
                showClose: true,
                message: res.system_result_message_key,
                type: "error",
              });
            }
          });
        }
      } else if (!!this.form.iccids.trim()) {
        // if(!Utils.checkICCID(this.form.iccids)){
        //   messageBox(this,'iccid只能包含数字和字母')
        //   return
        // }
        let params = new FormData();
        params.append("type", this.form.type);
        params.append("fromRange", false);
        let url;
         if (this.form.type == 7) {
          params.append("iccids", this.form.iccids);
          params.append("newMealId", this.form.newMeals);
          url = "card/import/changeMealSync.do?";
         
        }
        if (this.form.type == 10) {
          params.append("iccids", this.form.iccids);
          url = "record/cardRecycleRecord/cardRecycleByExcelSync.do?";
        }
        if (this.form.type == 25) {
           if (this.form.account == "") {
              this.$alert("请选择运营商", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
          params.append("iccids", this.form.iccids);
          params.append("accountId", this.form.account);
          params.append("setMealId", this.form.setMealId);
          url = "/card/accountEditByExcelSync.do?";
        }
        if (this.form.type == 26) {
          params.append("iccids", this.form.iccids);
          url = "/card/shortIccidEditByExcelSync.do?";
        }
        if (this.form.type == 32) {
          if (this.form.rechargeType == "") {
            this.$alert("请选择充值类型", "提示", {
              confirmButtonText: "确定",
            });
            return;
          }
          params.append("rechargeType", this.form.rechargeType);
          params.append("iccids", this.form.iccids);
          url = "/card/reChargeTypeEditByExcelSync.do?";
        }
          if (this.form.type == 33) {
          params.append("iccids", this.form.iccids);
          url = "/card/advanceLifeByExcelSync.do?";
        }

        importExcel(url, params).then((res) => {
          if (res.system_result_key == "0") {
            this.$alert("导入成功", "提示", {
              confirmButtonText: "确定",
            }).then(() => {
              this.dialogBox = false;
            });
          } else {
            this.$message({
              showClose: true,
              message: res.system_result_message_key,
              type: "error",
            });
          }
        });
      } else {
        if (this.form.type == 10) {
          messageBox(this, "请选择文件或输入iccid");
        } else {
          messageBox(this, "请选择文件");
        }

        return;
      }
    },
    loadFail(isId) {
      this.$router.push({
        path: "/cardManagement/batchCardFailList",
        query: { isId },
      });
    },

    searchTable() {
    this.currentPage = 1;
      this._importList();
    },
    importRecord() {
      this.dialogBox = true;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this._importList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this._importList();
    },
    _accountList() {
      if (this.form.type != 16 && this.form.type != 25) {
        return;
      }
      this.dialogLoading = true;
      var params = {};
      params.type = 7;
      params.action = this.form.action;
      params.daliangType = this.form.daliangType;
      params.expiredType = this.form.expiredType;
      params.newMeals = this.form.newMeals;
      accountList(params).then((res) => {
        this.imeiAccountList = res.map((item, i) => {
          if (i == 0) {
            // this.form.account = item.id
          }
          return {
            label: item.accountName,
            value: item.id,
          };
        });
        this.dialogLoading = false;
      });
    },
    _getSetMealByCategory() {
      if (this.form.type != 7) {
        return;
      }
      if (!this.isGetMeal) {
        return;
      }
      this.dialogLoading = true;
      this.isGetMeal = false;
      var params = {};
      params.type = 7;
      params.action = this.form.action;
      params.daliangType = this.form.daliangType;
      params.expiredType = this.form.expiredType;
      getSetMealByCategory(params).then((res) => {
        let meal = [];
        res.forEach((item, i) => {
          if (item.type === 1) {
            this.dialogLoading = false;
            return;
          } else {
            let _label = item.name;
            let accountName = item.accountName;
            meal.push({
              label: _label + "(" + accountName + ")",
              value: item.id,
            });
            if (i == 0) {
              this.form.newMeals = item.id;
            }
          }
        });
        this.newMealsList = meal;
        this.dialogLoading = false;
      });
    },
    _importList() {
      let params = {};
      params.pageSize = this.pageSize;
      params.pageNo = this.currentPage;
      params.type = this.type;
      importList(params).then((res) => {
        this.tableData = res.rows;
        this.pageSize = res.pageSize;
        this.currentPage = res.pageNo;
        this.total = res.total;
      });
    },
  },
  components: {
    SearchSelect,
  },
  computed: {
    ...mapGetters(["isCaptal",'currentRole','agentTree','isGuangyao']),
  },
};
</script>
